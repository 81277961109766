import React, { useEffect, useState } from 'react';

export function AddressHeader({ icon, title, hiddenChildren = null, hideButton = false, hasError = false, children }) {
    const [isVisible, setIsVisible] = useState(hideButton);

    useEffect(() => {
        if (hasError) {
            setIsVisible(true);
        }
    }, [hasError]);

    return (
        <>
            <div className="uk-flex uk-flex-middle uk-flex-between uk-margin-small-bottom uk-margin-small-top uk-padding-small uk-background-muted">
                <div>
                    <i className={`fa fa-fw ${icon} uk-margin-small-right`} />
                    {title}
                </div>
                {!hideButton && (
                    <button
                        type="button"
                        className="uk-padding-remove-vertical uk-button uk-button-link"
                        onClick={() => {
                            setIsVisible(prev => {
                                if (hasError) {
                                    return true;
                                } else {
                                    return !prev;
                                }
                            });
                        }}
                    >
                        <span data-uk-icon="icon: file-edit" /> Ändern
                    </button>
                )}
            </div>
            {isVisible || hideButton ? children : hiddenChildren}
        </>
    );
}
