import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

function Input({ name, value, label, onChange, onBlur, onFocus, error, className = 'uk-margin', type = 'text', placeholder, icon, ...rest }) {
    const innerOnChange = useCallback(({ target: { name, value } }) => onChange({ name, value }), [onChange]);

    return (
        <div className={className}>
            {label && <label className="uk-form-label">{label}</label>}
            <div className="uk-inline uk-width-1-1">
                {icon}
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={innerOnChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className="uk-input uk-form-controls"
                    {...rest}
                />
            </div>
            {error && <div className="uk-text-danger">{error}</div>}
        </div>
    );
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    error: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.node,
};

export default Input;
