import React from 'react';
import StandortLink from '../../components/VHost/StandortLink';

export default class CheckoutBreadCrumb extends React.Component {
    render() {
        const { partials, title, previousStep } = this.props;
        return (
            <div className="breadcrump_wrapper">
                <div>
                    <h2 className="breadcrump_wrapper__heading"> {title} </h2>
                    <div className="uk-flex uk-flex-between arrow-steps">
                        {partials
                            ? partials
                                  .filter(p => !p.key.includes('danke'))
                                  .map(partial => {
                                      const index = partials.indexOf(partial);
                                      return (
                                          <div
                                              className={`progress_titleData step ${partial.valid ? 'progressed' : null}${
                                                  partial.active ? ' active' : ' enabled'
                                              }`}
                                              key={index}
                                          >
                                              {/*current*/}
                                              {index < partials.length - 1 && !partial.valid ? (
                                                  <div
                                                      className={`title_inner disabled ${partial.active ? 'active' : null}`}
                                                      style={{ textDecoration: 'none', color: '#909090' }}
                                                  >
                                                      <span className="breadcrump_text">{partial.title}</span>
                                                  </div>
                                              ) : (
                                                  /*already done*/
                                                  <StandortLink className="title_inner enabled" to={`/checkout/${partial.key}`}>
                                                      <span className="breadcrump_text">{partial.title}</span>
                                                  </StandortLink>
                                              )}
                                          </div>
                                      );
                                  })
                            : null}
                    </div>
                </div>
                <div className="link-to-previous">
                    {previousStep ? (
                        <StandortLink to={`/checkout/${previousStep}`}>Zurück</StandortLink>
                    ) : (
                        <StandortLink to="/warenkorb">Zurück zum Warenkorb</StandortLink>
                    )}
                </div>
            </div>
        );
    }
}
