import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

function Radio({ name, value, label, innerOnChange, onBlur, checked }) {
    return (
        <label>
            <input
                className="uk-radio uk-form-controls"
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={innerOnChange}
                onBlur={onBlur}
            />{' '}
            {label}
        </label>
    );
}

Radio.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.node,
    innerOnChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    checked: PropTypes.bool.isRequired,
};

function RadioGroup({ name, value, label, options, onChange, onBlur, className = 'uk-margin', error }) {
    const innerOnChange = useCallback(({ target: { name, value } }) => onChange({ name, value }), [onChange]);

    return (
        <div className={className}>
            {label && <label className="uk-form-label">{label}</label>}
            <div className="uk-grid-small uk-child-width-auto uk-grid" data-uk-grid="">
                {options.map((option, index) => (
                    <Radio
                        key={index}
                        value={option.value}
                        name={name}
                        checked={option.value === value}
                        innerOnChange={innerOnChange}
                        onBlur={onBlur}
                        label={option.label}
                    />
                ))}
                {error && (
                    <div>
                        <div className="uk-text-danger">{error}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

RadioGroup.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.node,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    error: PropTypes.string,
};

export default RadioGroup;
