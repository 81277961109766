import React from 'react';
import CheckoutBreadCrumb from './CheckoutBreadCrumb';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { sessionIdSelector, userResponseSelector } from '../../../modules/selectors/auth';
import { standortUrlSelector } from '../../../modules/selectors/url';
import ReduxSuspense from '../../components/ReduxSuspense/ReduxSuspense';
import SalutationInformation, { SalutationInformationSchemaFields } from '../../components/form-fragments/SalutationInformation';
import { AddressHeader } from './CheckoutComponents/AddressDetailComponents/AddressHeader';
import Input from '../../components/form-elements/Input';
import RadioGroup from '../../components/form-elements/RadioGroup';
import { apiClient, useAsyncFunction } from '../../../helpers/apiHelperV2';
import Button from '../../components/form-elements/Button';
import StandortRedirect from '../../components/VHost/StandortRedirect';
import { InternalLink } from '../../components/form-elements/Link';

export const UserDataSchema = Yup.object().shape({
    invoiceAddress: Yup.object().shape(SalutationInformationSchemaFields),
    infoEmail: Yup.string().when('communicationChannel', {
        is: 'mail',
        then: Yup.string().email('Bitte gültiges E-Mail Format eingeben.').required('Pflichtfeld'),
    }),
    infoPhoneNumber: Yup.string().when('communicationChannel', {
        is: 'phone',
        then: Yup.string().required('Pflichtfeld'),
    }),
    communicationChannel: Yup.string().oneOf(['phone', 'mail']).required('Bitte wählen Sie Ihren bevorzugten Kontaktweg aus.'),
});

function oldSalutationToNewSalutation({ isCompany = false, companyName = '', salutation = '', titel = '', firstName = '', lastName = '' }) {
    return {
        isCompany,
        company: companyName,
        salutation,
        titel,
        firstName,
        lastName,
    };
}

function newSalutationToOldSalutation({ isCompany, company, salutation, titel, firstName, lastName }) {
    return {
        isCompany,
        companyName: company,
        salutation,
        titel,
        firstName,
        lastName,
    };
}

function Kontakt({ history }) {
    const userResponse = useSelector(userResponseSelector);
    const sessionId = useSelector(sessionIdSelector);
    const standortUrl = useSelector(standortUrlSelector);

    const initialValuesResponse = useAsyncFunction(async () => {
        const {
            data: { data = {} },
        } = await apiClient.post('/api/v1/checkout/kontakt', {
            sessionId,
            standortUrl: standortUrl,
        });

        if (data.redirect && typeof data.redirect !== 'boolean') return { redirectUrl: data.redirect };
        if (data.redirectUrl) return { redirectUrl: data.redirectUrl };
        const checkout = data.checkoutData || {};
        const steps = checkout.checkoutSteps ?? [];

        const initialValues = {
            invoiceAddress: oldSalutationToNewSalutation(checkout.userdata?.invoiceAddress ?? {}),
            infoEmail: checkout.userdata?.infoEmail ?? '',
            infoPhoneNumber: checkout.userdata?.infoPhoneNumber ?? '',
            communicationChannel: checkout?.appointment?.kontakt ?? '',
        };

        return {
            initialValues,
            steps,
            checkout,
        };
    }, [standortUrl, sessionId]);

    const handlePhoneNumberChange = ({ name, value }, setFieldValue) => {
        const formattedValue = value.replace(/[^0-9\s()+-]/g, '');
        setFieldValue(name, formattedValue);
    };

    return (
        <div className="checkout_wrapper">
            <ReduxSuspense response={initialValuesResponse}>
                {({ initialValues, steps, redirectUrl, checkout }) => {
                    if (redirectUrl) return <StandortRedirect to={redirectUrl} push />;
                    return (
                        <div className="checkout">
                            <CheckoutBreadCrumb partials={steps} standortUrl={standortUrl} title="Reservierung" />
                            <Formik
                                initialValues={initialValues}
                                validationSchema={UserDataSchema}
                                onSubmit={async (values, actions) => {
                                    try {
                                        if (values.deliveryToInvoice) {
                                            values.deliveryAddress = values.invoiceAddress;
                                        }

                                        const checkoutData = {
                                            userdata: {
                                                invoiceAddress: newSalutationToOldSalutation(values.invoiceAddress),
                                                infoEmail: values.infoEmail,
                                                infoPhoneNumber: values.infoPhoneNumber,
                                            },
                                            appointment: {
                                                ...checkout?.appointment,
                                                kontakt: values.communicationChannel,
                                                email: values.infoEmail,
                                                phone: values.infoPhoneNumber,
                                            },
                                        };
                                        const partial = steps.find(s => s.active);

                                        await apiClient.post('/api/v1/PersistCheckout', { checkoutData, sessionId });

                                        const {
                                            data: { data },
                                        } = await apiClient.post(`/api/v1/getNextCheckoutUrl/${partial.key}`, {
                                            sessionId,
                                        });

                                        return history.push(data.redirectUrl);
                                    } catch (err) {
                                        console.error(err);
                                        window.UIkit.notification('Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.', {
                                            status: 'error',
                                            pos: 'top-right',
                                        });
                                    } finally {
                                        actions.setSubmitting(false);
                                    }
                                }}
                            >
                                {({ values, errors, touched, setFieldValue, setFieldTouched, handleBlur }) => (
                                    <Form>
                                        <AddressHeader icon="fa-home" title="Kontaktdaten" hideButton>
                                            <ReduxSuspense response={userResponse}>
                                                {user => {
                                                    if (user) return null;
                                                    return (
                                                        <div>
                                                            Sie sind bereits registriert?{' '}
                                                            <InternalLink to="/login?context=checkout&type=reservierung">
                                                                Loggen Sie sich hier ein <i className="fa fa-chevron-right" />
                                                            </InternalLink>
                                                        </div>
                                                    );
                                                }}
                                            </ReduxSuspense>
                                            <SalutationInformation
                                                errors={errors.invoiceAddress ?? {}}
                                                touched={touched.invoiceAddress ?? {}}
                                                values={values.invoiceAddress}
                                                onChange={({ name, value }) => setFieldValue(`invoiceAddress.${name}`, value)}
                                                onBlur={({ target: { name } }) => setFieldTouched(`invoiceAddress.${name}`)}
                                            />
                                            <RadioGroup
                                                name="communicationChannel"
                                                label="Wie möchten Sie kontaktiert werden? *"
                                                value={values.communicationChannel}
                                                onChange={({ name, value }) => setFieldValue(name, value)}
                                                onBlur={handleBlur}
                                                options={[
                                                    { label: 'Email', value: 'mail' },
                                                    { label: 'Telefon', value: 'phone' },
                                                ]}
                                                error={
                                                    touched.communicationChannel && errors.communicationChannel
                                                        ? errors.communicationChannel
                                                        : undefined
                                                }
                                            />
                                            <Input
                                                name="infoEmail"
                                                value={values.infoEmail}
                                                label={`E-Mail Adresse ${values.communicationChannel === 'mail' ? '*' : ''}`}
                                                placeholder="E-Mail Adresse"
                                                onChange={({ name, value }) => setFieldValue(name, value)}
                                                onBlur={handleBlur}
                                                error={touched.infoEmail && errors.infoEmail ? errors.infoEmail : undefined}
                                            />
                                            <Input
                                                name="infoPhoneNumber"
                                                value={values.infoPhoneNumber}
                                                label={`Telefonnr. (tagsüber) ${values.communicationChannel === 'phone' ? '*' : ''}`}
                                                placeholder="Telefonnummer (tagsüber)"
                                                onChange={({ name, value }) => handlePhoneNumberChange({ name, value }, setFieldValue)}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.infoPhoneNumber && errors.infoPhoneNumber ? errors.infoPhoneNumber : undefined
                                                }
                                            />
                                        </AddressHeader>
                                        <Button fullWidth type="submit">
                                            WEITER ZUR AUTOHAUS AUSWAHL
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                            <div className="required-text uk-text uk-margin-small-top">
                                <p>* Pflichtfeld</p>
                            </div>
                        </div>
                    );
                }}
            </ReduxSuspense>
        </div>
    );
}

export default Kontakt;
