import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { signin } from '../../../modules/actions/user';
import Button from '../../components/form-elements/Button';
import { InternalLink } from '../../components/form-elements/Link';

const SigninSchema = Yup.object().shape({
    username: Yup.string().required('Pflichtfeld'),
    password: Yup.string().required('Pflichtfeld'),
});

function LoginForm({ standortUrl, signin }) {
    const handleSigninSubmit = async ({ username, password }, actions) => {
        await signin({ username, password });
        actions.setSubmitting(false);
    };

    return (
        <div className="uk-padding uk-padding-small aps-border aps-border-muted">
            <Formik initialValues={{ username: '', password: '' }} validationSchema={SigninSchema} onSubmit={handleSigninSubmit}>
                {() => (
                    <Form>
                        <div className="uk-margin">
                            <Field
                                name="username"
                                className="uk-input"
                                type="text"
                                placeholder="E-Mail Adresse oder Benutzernamen eingeben"
                                required=""
                            />
                            <ErrorMessage component="div" className="uk-text-danger" name="username" />
                        </div>
                        <div className="uk-margin">
                            <Field name="password" className="uk-input" type="password" placeholder="Passwort eingeben" required="" />
                            <ErrorMessage component="div" className="uk-text-danger" name="password" />
                        </div>
                        <div className="uk-margin">
                            <Button fullWidth type="submit">
                                Anmelden
                            </Button>
                        </div>
                        <div className="uk-margin">
                            <InternalLink to="/passwort-vergessen" className="uk-flex uk-flex-center">
                                Passwort vergessen?
                            </InternalLink>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const mapDispatchToProps = { signin };

export default connect(null, mapDispatchToProps)(LoginForm);
