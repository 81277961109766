import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Anchor from '../form-elements/Anchor';

function CredentialInformation({ title, counter = 1, hideEmailField }) {
    return (
        <li className="uk-open uk-margin-remove">
            <Anchor className="uk-accordion-title" style={{ fontSize: 'inherit' }}>
                <div className="uk-flex uk-flex-middle">
                    <span className="uk-badge aps-background-primary-light uk-margin-small-right">{counter}</span>
                    <div className="step-title uk-text-bold uk-text-large">{title}</div>
                </div>
            </Anchor>
            <div className="uk-accordion-content uk-margin-remove">
                <div style={{ marginLeft: 10, paddingLeft: 10, borderLeft: '1px solid grey' }}>
                    <div className="uk-padding-small">
                        <div className="uk-text-bold">Alle mit * gekennzeichneten Felder sind Pflichtfelder</div>
                        {!hideEmailField && (
                            <div className="uk-margin">
                                <label className="uk-form-label">E-Mail-Adresse *</label>
                                <Field name="email" className="uk-input" type="email" placeholder="E-Mail-Adresse" />
                                <ErrorMessage component="div" className="uk-text-danger" name="email" />
                            </div>
                        )}
                        <div className="uk-margin">
                            <label className="uk-form-label">Passwort *</label>
                            <Field name="password" className="uk-input" type="password" placeholder="Passwort" />
                            <ErrorMessage component="div" className="uk-text-danger" name="password" />
                        </div>
                        <div className="uk-margin">
                            <label className="uk-form-label">Passwort wiederholen *</label>
                            <Field name="confirmPassword" className="uk-input" type="password" placeholder="Passwort wiederholen" />
                            <ErrorMessage component="div" className="uk-text-danger" name="confirmPassword" />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default CredentialInformation;
