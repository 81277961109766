import React from 'react';
import Input from '../../../components/form-elements/Input';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { addCoupon } from '../../../../modules/actions/standort/session/cart';
import Button from '../../../components/form-elements/Button';

export default function CouponInput() {
    const dispatch = useDispatch();

    return (
        <div className="uk-section">
            <Formik
                initialValues={{ couponCode: '' }}
                onSubmit={({ couponCode }) => {
                    dispatch(addCoupon({ couponCode }));
                }}
                render={({ values, setFieldValue }) => {
                    return (
                        <Form>
                            <Input
                                label="Gutscheincode"
                                name="couponCode"
                                value={values.couponCode}
                                onChange={({ name, value }) => setFieldValue(name, value)}
                            />
                            {values.couponCode && (
                                <Button type="submit" fullWidth>
                                    Einlösen
                                </Button>
                            )}
                        </Form>
                    );
                }}
            />
        </div>
    );
}
