import React from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { ErrorMessage, FastField, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { apiPost } from '../../../helpers/apiHelper';
import { carBrandNamesSelector } from '../../../modules/selectors/carBrands';
import { bindActionCreators } from 'redux';
import { fetchCarBrandsIfNeeded } from '../../../modules/actions/carBrands';
import { frontloadConnect } from 'react-frontload';
import { trackEvent } from '../Tracking/Matomo';
import Button from '../form-elements/Button';
import { InternalLink } from '../form-elements/Link';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { ContactPhone } from '../Meisterkachel/Contact';
import Select from '../form-elements/Select';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const zipRegExp = /^[0-9]{5}$/;
// changed for requirements in TTP-2309
const phoneRegExp = /^[0-9]+$/;
const zipRegExp = /^[0-9]{4,5}$/;

function ContactForm({ carBrandNames, closeContactModal }) {
    const markenStandort = useSelector(markenStandortSelector);
    const productID = useSelector(state => state.productId?.data ?? '');

    const getLink = (link) => {
        let shopLink = link;
        if(link.includes('.localhost:3000')) {
            shopLink = link.replace('.localhost:3000', '');
        }
        return shopLink;
    }

    const { implementsAreaConcept, areaShopSubLocations, nebenFabrikat, url } = markenStandort;
    // alle Marken oder wenn AreaShop: Marken aller Sublocations. Sonst Hauptfabrikat des Standorts.
    const carMakes =
        // (carBrandNames && carBrandNames.length > 0 && carBrandNames) ||
        (implementsAreaConcept && url === 'gottfried-schultz'
            ? areaShopSubLocations && _.uniq([...areaShopSubLocations.map(loc => loc.hauptFabrikat), ...nebenFabrikat])
            : implementsAreaConcept && url !== 'gottfried-schultz' ? areaShopSubLocations && _.uniq([...areaShopSubLocations.map(loc => loc.hauptFabrikat), ...nebenFabrikat]) : (carBrandNames && carBrandNames.length > 0 && carBrandNames));
    const initialValues = {
        serviceNotes: '',
        lastName: '',
        salutation: '',
        plz: '',
        contactBy: '',
        contact_email: '',
        contact_telephone: '',
        dsgvo: false,
        carMake: carMakes.length === 1 ? carMakes[0] : '',
        inquiryType: 'contactrequest',
        standortUrl: markenStandort.url || 'de',
        shopCurrentpage: getLink(window.location.href),
        articleNummer: productID,
    };

    const ContactSchema = Yup.object().shape({
        serviceNotes: Yup.string().required('Pflichtfeld'),
        lastName: Yup.string().required('Pflichtfeld'),
        salutation: Yup.string().required('Pflichtfeld'),
        zip: Yup.string().required('Pflichtfeld').matches(zipRegExp, 'Bitte geben Sie eine valide Postleitzahl ein.'),
        contactBy: Yup.string(),
        contact_email: Yup.string().email('E-Mail ungültig').required('Pflichtfeld'),
        contact_telephone: Yup.string().matches(phoneRegExp, 'Bitte geben Sie eine valide Telefonnummer ein.'),
        dsgvo: Yup.bool().oneOf([true], 'Pflichtfeld').required('Pflichtfeld'),
        carMake: markenStandort && markenStandort.isTechno ? Yup.string().required('Pflichtfeld') : Yup.string(),
        inquiryType: Yup.string(),
        standortUrl: Yup.string(),
        shopCurrentpage: Yup.string(),
        articleNummer: Yup.string(),
    });

    return (
        <div className="uk-width-xlarge uk-margin-auto contact_form_root">
            <div className="uk-panel uk-padding-small uk-background-secondary uk-light">
                <div className="uk-flex uk-flex-middle">
                    <h2 className="uk-margin-remove">Ihre Anfrage</h2>
                    <button
                        className="uk-margin-auto-left uk-icon-button"
                        data-uk-icon="close"
                        onClick={closeContactModal}
                        data-uk-ttoltip="Kontakt schließen"
                        aria-label="Kontakt schließen"
                    />
                </div>
            </div>
            <div className="uk-padding-small uk-padding-remove-top" data-uk-margin="">
                <Formik
                    initialValues={initialValues}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                        apiPost('/api/v1/ContactRequest', values, function (hasError) {
                            if (hasError) {
                                window.UIkit.notification('Beim senden ist ein Fehler aufgetreten.', {
                                    status: 'danger',
                                    pos: 'top-right',
                                });
                            } else {
                                trackEvent({
                                    category: 'Anfrage',
                                    action: 'Submit-Kontakt-Anfrage',
                                    label: values && values.standortUrl,
                                    standort: markenStandort,
                                });
                                window.UIkit.notification('Ihre Kontaktanfrage wurde erfolgreich verschickt.', {
                                    status: 'success',
                                    pos: 'top-right',
                                });
                            }
                            actions.setSubmitting(false);
                            actions.resetForm();
                            closeContactModal();
                        });
                    }}
                    render={({ values, errors, touched, handleBlur, setFieldValue }) => {
                        const onChange = ({ name, value }) => setFieldValue(name, value);
                        return (
                            <Form className="uk-form-stacked">
                                <h4 className="uk-text-bold">Wie können wir Ihnen helfen?</h4>
                                {markenStandort.anschrift && markenStandort.anschrift.telefon && (
                                    <div>
                                        <p>Rufen Sie uns an </p>
                                        <div className="uk-flex uk-flex-center">
                                            <ContactPhone phone={markenStandort.anschrift.telefon} hideArrow />
                                        </div>
                                    </div>
                                )}
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="serviceNotes" style={{ fontSize: '1.4rem' }}>
                                        Nennen Sie uns Ihr Anliegen *
                                    </label>
                                    <div className="uk-form-controls">
                                        <FastField
                                            component="textarea"
                                            className="uk-textarea"
                                            name="serviceNotes"
                                            rows="5"
                                            placeholder="Beschreiben Sie Ihr Anliegen"
                                        />
                                        <ErrorMessage name="serviceNotes">{msg => <div className="uk-text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h4>Kontaktdaten</h4>
                                    <p>
                                        Damit wir Ihnen schnellstmöglich bei Ihrem Anliegen weiterhelfen können, hinterlassen Sie uns bitte
                                        Ihre Kontaktdaten
                                    </p>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="salutation" style={{ fontSize: '1.4rem' }}>
                                        Anrede *
                                    </label>
                                    <div className="uk-form-controls">
                                        <Select
                                            name="salutation"
                                            value={values.salutation}
                                            onBlur={handleBlur}
                                            onChange={onChange}
                                            options={[
                                                {
                                                    label: 'Anrede wählen',
                                                    value: '',
                                                },
                                                { label: 'Frau', value: 'Frau' },
                                                { label: 'Herr', value: 'Herr' },
                                                { label: 'Divers', value: 'Divers' },
                                            ]}
                                            error={touched.salutation && errors.salutation ? errors.salutation : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="lastName" style={{ fontSize: '1.4rem' }}>
                                        Nachname *
                                    </label>
                                    <div className="uk-form-controls">
                                        <FastField className="uk-input" name="lastName" placeholder="Mustermann" />
                                        <ErrorMessage name="lastName">{msg => <div className="uk-text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="contact_email" style={{ fontSize: '1.4rem' }}>
                                        E-Mail *
                                    </label>
                                    <div className="uk-form-controls">
                                        <FastField
                                            className="uk-input"
                                            name="contact_email"
                                            type="email"
                                            placeholder="m.mustermann@internet.de"
                                        />
                                        <ErrorMessage name="contact_email">
                                            {msg => <div className="uk-text-danger">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="contact_telephone" style={{ fontSize: '1.4rem' }}>
                                        Telefon
                                    </label>
                                    <div className="uk-form-controls">
                                        <FastField className="uk-input" name="contact_telephone" placeholder="1234 / 87654321" />
                                        <ErrorMessage name="contact_telephone">
                                            {msg => <div className="uk-text-danger">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="zip" style={{ fontSize: '1.4rem' }}>
                                        Postleitzahl *
                                    </label>
                                    <div className="uk-form-controls">
                                        <FastField className="uk-input" name="zip" placeholder="PLZ" />
                                        <ErrorMessage name="zip">{msg => <div className="uk-text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="carMake" style={{ fontSize: '1.4rem' }}>
                                        Marke
                                    </label>
                                    <div className="uk-form-controls">
                                        <FastField component="select" className="uk-select" name="carMake" placeholder="Marke">
                                            <option value="">Automarke wählen</option>
                                            {carMakes &&
                                                carMakes.map((carMake, index) => (
                                                    <option key={index} value={carMake}>
                                                        {carMake}
                                                    </option>
                                                ))}
                                        </FastField>
                                        <ErrorMessage name="carMake">{msg => <div className="uk-text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </div>
                                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                    <label>
                                        <FastField type="checkbox" name="dsgvo" className="uk-checkbox" /> Ich habe die
                                        <InternalLink to="/datenschutz" newTab>
                                            {' '}
                                            Datenschutzhinweise{' '}
                                        </InternalLink>
                                        zur Kenntnis genommen.
                                    </label>
                                    <ErrorMessage name="dsgvo">{msg => <div className="uk-text-danger">{msg}</div>}</ErrorMessage>
                                </div>
                                <div className="uk-margin-bottom">* Pflichtfelder</div>
                                <Button type="submit" fullWidth modifier="secondary">
                                    Daten absenden
                                </Button>
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    );
}
const frontload = async ({ markenStandort, fetchCarBrandsIfNeeded }) => {
    if (markenStandort && markenStandort.showAllBrands) await fetchCarBrandsIfNeeded();
};
const mapDispatchToProps = dispatch => bindActionCreators({ fetchCarBrandsIfNeeded }, dispatch);

const mapStateToProps = state => ({
    carBrandNames: carBrandNamesSelector(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload)(ContactForm));
