import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CredentialInformation from '../../components/authentication/CredentialInformation';
import { resetUserPassword } from '../../../modules/actions/user';
import { connect } from 'react-redux';
import { trackEvent } from '../../components/Tracking/Matomo';
import Button from '../../components/form-elements/Button';
import { useStandortHistory } from '../../components/VHost/StandortHistory';

const ResetUserPasswordSchema = Yup.object().shape({
    email: Yup.string().required('Pflichtfeld'),
    password: Yup.string().required('Pflichtfeld'),
    confirmPassword: Yup.string()
        .required('Pflichtfeld')
        .test('confirmPassword', 'Passwörten stimmen nicht überein', function (value) {
            return this.parent.password === value;
        }),
});

function PasswordReset({ resetUserPassword }) {
    const { hash } = useParams();
    const history = useStandortHistory();

    const handleResetCredentialsSubmit = async ({ email, password }, actions) => {
        await resetUserPassword({ hash, email, password });
        trackEvent({ category: 'Account', action: 'Submit-Passwort-Vergessen', label: 'passwordreset' });
        actions.setSubmitting(false);
        return history.push(`/login`);
    };

    return (
        <div className="uk-container uk-container-xsmall">
            <Formik
                initialValues={{ email: '', password: '', confirmPassword: '' }}
                validationSchema={ResetUserPasswordSchema}
                onSubmit={handleResetCredentialsSubmit}
                render={() => {
                    return (
                        <Form className="uk-form">
                            <ul data-uk-accordion="multiple: true">
                                <CredentialInformation title="Passwort wiederherstellen" />
                                <Button fullWidth type="submit">
                                    Mein Passwort wiederherstellen
                                </Button>
                            </ul>
                        </Form>
                    );
                }}
            />
        </div>
    );
}

const mapDispatchToProps = { resetUserPassword };

export default connect(null, mapDispatchToProps)(PasswordReset);
