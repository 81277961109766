import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LoginForm from './LoginForm';
import StandortRedirect from '../../components/VHost/StandortRedirect';
import Anchor from '../../components/form-elements/Anchor';
import BreadCrumbSelector from '../../components/BreadCrumb/BreadCrumbSelector';
import { InternalLink } from '../../components/form-elements/Link';
import { parseSearch } from '../../../helpers/UrlHelper';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { userSelector } from '../../../modules/selectors/auth';

function AuthenticationSelector() {
    const location = useLocation();
    const { context, type } = parseSearch(location?.search);
    const standortUrl = useSelector(standortUrlSelector);
    const user = useSelector(userSelector);

    if (user?._id) {
        let redirectUrl = `/account`;
        if (context === 'checkout') {
            if (type) {
                redirectUrl = `/initiateCheckout?type=${type}`;
            } else {
                redirectUrl = `/warenkorb`;
            }
        }
        return <StandortRedirect to={redirectUrl} />;
    }

    return (
        <>
            <BreadCrumbSelector additionalCrumbs={[{ label: 'Login' }]} />
            <section className="uk-section uk-section-default uk-margin">
                <div className="uk-container">
                    <div className="uk-text-center uk-margin">
                        <h2 className="uk-text-muted">LOGIN</h2>
                    </div>
                    <p className="uk-text-muted uk-margin">
                        Herzlich willkommen <br />
                        Um auf Ihr Kundenkonto zugreifen zu können, loggen Sie sich bitte ein.
                        <br />
                        Sollten Sie noch kein Kundenkonto haben, so können Sie sich bei uns registrieren.
                    </p>
                </div>
            </section>
            <section className="uk-section uk-section-default uk-margin-xlarge-bottom">
                <div className="uk-container ">
                    <ul data-uk-accordion="">
                        <li>
                            <Anchor className="uk-accordion-title aps-accordion-title uk-background-muted uk-padding-small">
                                <span data-uk-icon="user" /> Ich bin bereits Kunde - Login
                            </Anchor>
                            <div className="uk-accordion-content uk-margin-remove">
                                <LoginForm standortUrl={standortUrl} />
                            </div>
                        </li>
                        <li>
                            <Anchor className="uk-accordion-title aps-accordion-title uk-background-muted uk-padding-small">
                                <span className="uk-position-relative uk-text-danger" data-uk-icon="user">
                                    <span className="uk-position-absolute" style={{ top: -5, right: -5, fontSize: '0.5rem' }}>
                                        NEU
                                    </span>
                                </span>{' '}
                                Ich bin neu hier - Registrierung
                            </Anchor>
                            <div className="uk-accordion-content uk-margin-remove">
                                <div className="uk-padding uk-padding-small aps-border aps-border-muted" data-uk-margin="">
                                    <h4>Mit dem Kundenkonto Vorteile sichern</h4>
                                    <h3 className="uk-text-muted">
                                        <i className="fa fa-check aps-text-primary" />
                                        <span> Bestellung speichern und wiederholen</span>
                                    </h3>
                                    <p>
                                        Sie haben die Möglichkeit bequem einmal getätigte Bestellungen mit einem Klick erneut zu bestellen.
                                    </p>
                                    <InternalLink modifier="primary" to={`/registrieren${location?.search ?? ''}`} fullWidth>
                                        Registrieren
                                    </InternalLink>
                                </div>
                            </div>
                        </li>
                        {context === 'checkout' && (
                            <InternalLink
                                className="uk-accordion-title aps-accordion-title uk-background-muted uk-padding uk-padding-small"
                                to={`/initiateCheckout${location?.search ?? ''}`}
                            >
                                <i className="fa fa-user" /> Ich möchte als Gast bestellen
                            </InternalLink>
                        )}
                    </ul>
                </div>
            </section>
        </>
    );
}

export default AuthenticationSelector;
